





























import { Component, Vue } from "vue-property-decorator";

@Component
export default class SidebarFinanceReports extends Vue {
public data = [
    {
      item: "Finance",
      visibility: false,
      collapse: "collapse-1",
      child: [
        // { item: "List", route: "/" },
        // { item: "Salary", route: "/" },
        // { item: "Iqama", route: "/" },
        // { item: "Passport", route: "/" },
        { item: "Balance Sheet", route: "/balancesheet" },
        { item: "Profit & Loss", route: "/profitloss" },
      ],
    },
    // {
    //   item: "Ledger",
    //   visibility: false,
    //   collapse: "collapse-2",
    //   child: [
    //     { item: "Statements of Accounts", route: "/ledger/statements-of-accounts" }
    //   ],
    // },
  ];

  mounted() {
    let index = this.data.findIndex((x) => {
      return x.item === this.$route.name;
    });
    if (index >= 0) {
      this.data[index]["visibility"] = true;
    }
  }
}
